import React from "react"
import Layout from "../components/common/layout"
import CorsoCard from "../components/cards/corsoCard"
import blogStyles from "../styles/pages/blog.module.scss"
import { graphql } from 'gatsby'

const CorsiPage = ({ data, location }) => {
  return (
    <Layout
      pageMeta={{
        title: "Corsi di Formazione Olistica",
        keywords: ["Savona", "riflessologia plantare", "counseling", "reiki", "massaggio"],
        description:
          "Corsi di formazione di Riflessologia Plantare Relazionale, Massaggio e Counseling, Reiki, Psicosomatica.",
        link: "/corsi",
      }}
      location={location}
    >
      <h1 className="page-title">corsi</h1>
      <ul className={blogStyles.blog}>
        {data.allStrapiCorsi.edges.map(post => (
          <li key={post.node.strapiId}>
            <CorsoCard post={post} />
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default CorsiPage

export const corsoPageQuery = graphql`
  query CorsoPageQuery {
    allStrapiCorsi(sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          strapiId
          titolo
          slug
          testo
          foto {
            name
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`