import React from "react"
import { Link } from "gatsby"
import postCardStyles from "../../styles/cards/blog-post-card.module.scss"
import Img from "gatsby-image"

const CorsoCard = ({ post }) => {
  const isDocumentValid = post.node.foto && post.node.titolo && post.node.slug
  if (isDocumentValid) {
  return (
    <div className={postCardStyles.cardBorderWrap}>
    <Link className={postCardStyles.card} to={`/corsi/${post.node.slug}`}>
      <Img
        className={postCardStyles.image}
        fluid={post.node.foto.childImageSharp.fluid}
        alt={`foto natura - ${post.node.foto.name}`}
      />
      <span className={postCardStyles.title}>{post.node.titolo}</span>
    </Link></div>
  )} return null
}

export default CorsoCard